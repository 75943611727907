import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "gatsby-image"
import styled from "styled-components"
import {useIntl} from "gatsby-plugin-intl"

const PostImage = styled.div`
  margin: calc(var(--spacing) * 2) 0;

  @media (min-width: 1200px) {
    margin: calc(var(--spacing) * 4) 0;
  }
`


const Index = ({ data }) => {
  const intl = useIntl()
  const { allFile } = data
  return (
    <Layout>
      <Seo title={intl.formatMessage({id: "home"})} />
      <h3>‎ </h3>
      <p>
        {intl.formatMessage({id: "home_title"})}
      </p>
      <p>
        {intl.formatMessage({id: "home_text1"})}
      </p>
      <PostImage>
        <Img fluid={allFile.edges[0].node.childImageSharp.fluid}/>
      </PostImage>
    </Layout>
  )
}

export const pageQuery = graphql`
    query MyQuer {
        allFile(filter: {name: {eq: "home"}}) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxHeight: 600) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }

`

export default Index
